import React from 'react';
import ReactDOM from 'react-dom';
import BreadCrumb from "./breadcrumb.jsx";
import ErrorBoundary from './errorBoundary.jsx';

let env = 'prod';

let params = window.location.search;
let hrefName = "/" + ((window.location.pathname + window.location.hash).substring(1)) + params;

let treeData = [
  {
    "text": "Getting Started",
    "nodes": [
      {
        "text": "Introduction to Direct+",
        "href": "/html/pages/DplusIntroduction.html",
      },
      {
        "text": "Using the D&B Direct+ APIs",
        "href": "/html/pages/UsingDplusAPIs.html",
      },
    ]
  },
  {
    "text": "Guides",
    "nodes": [
      {
        "text": "Authentication",
        "href": "/html/pages/Authentication.html",
      },
      {
        "text": "Identify",
        "nodes": [
          {
            "text": "Identity Resolution",
            "href": "/html/guides/Identify/IdentityResolution.html"
          },
          {
            "text": "Multi-Process",
            "href": "/html/guides/Identify/MultiProcess.html"
          },
          {
            "text": "Search",
            "href": "/html/guides/Identify/Search.html"
          },
          {
            "text": "Which Identity Resolution Method to Use",
            "href": "/html/guides/Identify/WhichMatchtoUse.html"
          }
        ]
      },
      {
        "text": "Enrich",
        "href": "/html/guides/Enrich/Enrich.html"
      },			  
      {
		"text": "Data File (Batch)",
		"href": "/html/guides/Enrich/DataFile.html"
	  },
	  {
		"text": "Email Verification (Batch)",
		"href": "/html/guides/Enrich/DataFileBatch.html"
	  },
	  {
		"text": "Research",
		"href": "/html/guides/Research/Research.html"
	  },
      {
        "text": "Monitor",
        "href": "/html/guides/Monitor/Overview.html"
      },
      {
        "text": "D&B Connect",
        "href": "/html/guides/DNBConnect/DNBConnect.html"
      },
      {
        "text": "Reference Data",
        "href": "/html/guides/Identify/ReferenceData.html"
      },
      {
        "text": "Analytics Score",
        "href": "https://www.dnb.com/utility-pages/dnb-analytics-studio-references.html",
        "target": "_blank"
      },
      {
        "text": "FAQ",
        "href": "/html/resources/FAQ.html"
      }
    ]
  },
  {
    "text": "Resources",
    "nodes": [
      {
        "text": "Data Dictionary",
        "href": "/dictionary.html"
      },
      {
        "text": "Errors and Information Messages",
        "href": "/errorsAndInformationMessages.html"
      },
      {
        "text": "Country Codes",
        "href": "/html/resources/CountryCodes.html"
      },
      {
        "text": "JSON Samples",
        "href": "/html/resources/JSONSamplesOverview.html",
      },
      {
        "text": "Monitoring Sample Deliveries",
        "href": "/html/resources/MonitoringSampleFiles.html"
      },
      {
        "text": "Multi-Process Sample Files",
        "href": "/html/resources/MultiProcessSampleFiles.html"
      },
      {
        "text": "Postman Collections",
        "href": "/html/resources/PostmanCollections.html"
      },
      {
        "text": "Change History",
        "href": "/html/resources/ChangeHistory.html"
      }
    ]
  },
  {
    /*Standard depth is 3 levels maximum.  If 3rd level is not API, the category leads to a page listing the individual APIs. */
    "text": "API Reference",
    "nodes": [
      {
        "text": "Authentication",
        "href": "/openAPI.html?apiID=authenticationV3"
      },
      {
        "text": "Identify",
        "nodes": [
          {
            "text": "Identity Resolution",
            "href": "/html/pages/IdentityResolutionAPIs.html",
          },
          {
            "text": "Search",
            "href": "/html/pages/SearchAPIs.html",
          },
          {
            "text": "Web Visitor Identification",
            "href": "/html/pages/WVIAPIs.html",
          },
          {
            "text": "Multi-Process",
            "href": "/html/pages/MultiProcessAPIs.html",
          }
        ]
      },
      {
        "text": "Enrich",
        "nodes": [
           {
            "text": "Data Blocks",
            "href": "/DataBlocks.html"
          },
          {
            "text": "Corporate Linkage, Family Tree",
            "href": "/html/pages/CorporateLinkageAPIs.html"
          },
          {
            "text": "Resolved Network Insights",
            "href": "/html/pages/ResolvedNetworkInsightsAPIs.html"
          },
          {
            "text": "Custom Analytics",
            "href": "/openAPI.html?apiID=customscore"
          },
          {
            "text": "Company Report",
             "href": "/openAPI.html?apiID=companyReport"
          },
          {
            "text": "News and Media, Standard",
            "href": "/openAPI.html?apiID=namstd"
          },
          {
            "text": "Industry Profile",
            "href": "/html/pages/IndustryProfileAPIs.html"
          },
          {
            "text": "Restricted Party Screening",
			"href": "/html/pages/RestrictedPartyScreening.html"
          },
          {
            "text": "IP Intel",
			"href": "/html/pages/IPIntelligence.html"
          },
          {
            "text": "Fraud Risk Insights",
			"href": "/html/pages/BusinessFraudRiskInsightsAPIs.html"
          },
          {
            "text": "SME Banking",
			"href": "/html/pages/SMEBankingAPIs.html"
          },
          {
            "text": "D&B Blended Score",
            "href": "/html/pages/BlendedScoreAPIs.html"
          },
          {
            "text": "Consumer Risk Reports",
            "href": "/html/pages/ConsumerRiskReportsAPIs.html"
          },
          {
            "text": "Data Product: Analytics",
            "href": "/html/pages/AnalyticsAssessmentAPIs.html"
          },
          {
            "text": "Data Product: Company Profile",
            "href": "/html/pages/CompanyProfileAPIs.html"
          },
          {
            "text": "Email Verification",
            "href": "/openAPI.html?apiID=emailverification"
          },
          {
            "text": "Consolidated Assessment Report (CAR) Investigation",
            "href": "/html/pages/CARInvestigationAPIs.html"
          }
              ]
       },
      {
        "text": "Research",
        "href": "/html/pages/ResearchAPIs.html"
      },
      {
        "text": "Monitor",
        "nodes": [
          {
            "text": "Monitoring API Overview",
            "href": "/html/pages/MonitoringAPIs.html",
          },
          {
            "text": "Add Subjects to existing registration",
            "href": "/openAPI.html?apiID=monAddSubjectsToRegistration"
          },
          {
            "text": "Add Single Subject to existing registration",
            "href": "/openAPI.html?apiID=monAddSingleSubjectToRegistration"
          },      
          {
            "text": "Check for Subject in Registration",
            "href": "/openAPI.html?apiID=monCheckSubject"
          },      
          {
            "text": "Export Subjects from Registration",
            "href": "/openAPI.html?apiID=monExportSubjects"
          },      
          {
            "text": "Remove Subjects from Registration",
            "href": "/openAPI.html?apiID=monRemoveSubjectsFromRegistration"
          },     
          {
            "text": "Remove Single Subject from Registration",
            "href": "/openAPI.html?apiID=monRemoveSingleSubjectFromRegistration"
          },     
          {
            "text": "Get Registration Status",
            "href": "/openAPI.html?apiID=monGetRegistrationStatus"
          },
          {
            "text": "List pending deliveries",
            "href": "/openAPI.html?apiID=monListPendingArtifacts"
          },
          {
            "text": "List Registrations",
            "href": "/openAPI.html?apiID=monListRegistrations"
          },
          {
            "text": "Registrations: Get Details, Edit, and Delete",
            "href": "/openAPI.html?apiID=monGetRegistration"
          },
          {
            "text": "Request Notifications be sent",
            "href": "/openAPI.html?apiID=monTriggerPush"
          },
          {
            "text": "On Hold Notifications",
            "href": "/openAPI.html?apiID=monOnHoldNotifications"
          },
          {
            "text": "Suppress Notifications",
            "href": "/openAPI.html?apiID=monSuppressNotifications"
          },
          {
            "text": "Pull new Notifications",
            "href": "/openAPI.html?apiID=monRegistrationPullAPI"
          },
          {
            "text": "Replay(re-pull) Notifications",
            "href": "/openAPI.html?apiID=monRegistrationPullAPIReplay"
          }
        ]
      },
      {
        "text": "Email Verification (Batch)",
        "href": "/html/pages/DataFileBatchAPIs.html",
      },
      {
        "text": "Reference Data",
        "href": "/html/pages/ReferenceDataAPIs.html",
      },
      {
        "text": "Entitlements",
        "href": "/openAPI.html?apiID=entitlements"
      }
    ]
  },
  {
    "text": "Internal",
    "display": ["stg", "dev"],
    "nodes": [
      {
        "text": "APIs",
        "display": ["stg", "dev"],
        "nodes": [
          {
            "text": "Limited Availability",
            "display": ["stg", "dev"],
            "href": "/html/staging/LimitedAvailAPIs.html",
          },
          {
            "text": "Internal",
            "display": ["stg", "dev"],
            "href": "/html/staging/InternalAPIs.html",
          },
          {
            "text": "Not Yet GTM",
            "href": "/html/staging/NotGTMAPIs.html",
            "display": ["stg", "dev"],
          }
        ]
      },
      {
        "text": "Data Blocks Internal",
        "href": "/DataBlocksInternal.html",
        "display": ["stg", "dev"]
      }, 
      {
        "text": "Internal Resources",
        "href": "/html/staging/InternalResources.html",
        "display": ["stg", "dev"]
      }, 
      {
        "text": "Guides",
        "display": ["stg", "dev"],
        "nodes": [
			  {
				"text": "Mini-Investigation",
				"href": "/html/guides/Identify/MiniInvestigation.html",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "Mini-Investigation (Legacy)",
				"href": "/html/guides/Identify/MiniInvestigationLegacy.html",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "DataFileBatch",
				"href": "/html/guides/Enrich/DataFileBatchNew.html",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "Monitor (Old)",
				"href": "/html/guides/Monitor/Monitoring.html",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "Monitoring Dashboard Guide DRAFT (word file)",
				"href": "/html/staging/files/Monitoring_Dashboard_Guide.docx",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "On Behalf of Users",
				"href": "/html/staging/OnBehalfOfUsers.html",
				"display": ["stg", "dev"]
			  },
			  {
				"text": "Internal Subscriber Provisioning",
				"href": "/html/staging/InternalSubscriberProvisioning.html",
				"display": ["stg", "dev"]
			  }
        ]
      },
      {
        "text": "Open API (Swagger) Specifications (Decom.,)",
        "href": "/html/resources/OpenAPISpecs.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Quick Links to All APIs",
        "href": "/html/staging/APIQuickLinks.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Create Monitoring Registration",
        "href": "/html/guides/Monitor/CreateMonitoringRegistration.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Postman Tutorial",
        "href": "/html/resources/PostmanTutorial.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Enrich Interpreting Assessment",
        "href": "/html/guides/Enrich/InterpretingAssessment.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Interpreting Assessments NOT GTM Products DRAFT",
        "href": "/html/staging/InterpretingAssessmentNOTGTM.html",
        "display": ["stg", "dev"]
      },
      {
        "text": "Direct 1.x Migration Information",
        "href": "/html/staging/files/Direct1x_Migration.docx",
        "display": ["stg", "dev"]
      }
    ]
  }
];

class NavArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {nodes: this.props.items};
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      let newTree = (function recurse(nodes) {
        if (nodes) {
          return nodes.map(node => {
            return Object.assign({}, node, {
              expanded: false,
              nodes: recurse(node.nodes)
            })
          });
        }
      })(this.state.nodes);
      this.setState({nodes: newTree});
    }
  }

  nodeExpandOrCollapse(selectedNodeID, parentNodeID) {
    let newTree = (function recurse(nodes) {
      if (nodes) {
        return nodes.map(node => {
          let newExpandStatus = (node.nodeID === selectedNodeID || node.nodeID === parentNodeID) ? true : false;
          return Object.assign({}, node, {
            expanded: newExpandStatus,
            nodes: recurse(node.nodes)
          })
        });
      }
    })(this.state.nodes);
    this.setState({nodes: newTree});
  }

  produceMenuEntries(items, level) {
    let menuEntries = [];
    items.forEach(node => {
      let menuEntry = '';
      let subMenuClass = 'dropdown-submenu';
      let menuClass = "dropdown";
      if (node.expanded) {
        subMenuClass = 'dropdown-submenu open';
        menuClass = "dropdown open";
      }
      if (node.hasOwnProperty('nodes') && typeof node.nodes != 'undefined') {
        if (level === 0) {
          menuEntry = (
            <li className={menuClass} key={node.text}>
              <a onClick={this.nodeExpandOrCollapse.bind(this, node.nodeID, node.parentNodeID)}>
                {node.text}<span className="caret"></span></a>
              <ul className="dropdown-menu">
                {this.produceMenuEntries(node.nodes, level + 1)}
              </ul>
            </li>
          );
        } else {
          menuEntry = (
            <li className={subMenuClass} key={node.text}>
              <a onClick={this.nodeExpandOrCollapse.bind(this, node.nodeID, node.parentNodeID)}>{node.text}</a>
              <ul className={"dropdown-menu " + (node.containsOnlyLeaves ? 'pre-scrollable' : '')}>
                {this.produceMenuEntries(node.nodes, level + 1)}
              </ul>
            </li>
          );
        }
      } else {
        menuEntry = node.target ? <li key={node.text}><a href={node.href} target={node.target}>{node.text}</a></li> : <li key={node.text}><a href={node.href}>{node.text}</a></li>;
      }
      menuEntries.push(menuEntry);
    });
    return menuEntries;
  }

  render() {
    return (
      <ErrorBoundary message={'unable to render Menu'}>
        <div ref={this.setWrapperRef}>
          <ul className="nav navbar-nav navbar-right">
            {this.produceMenuEntries(this.state.nodes, 0)}
          </ul>
        </div>
      </ErrorBoundary>
    )
  }

}

ReactDOM.render(<NavArea items={getTree()}/>, document.getElementById('topMenu'));

ReactDOM.render(<BreadCrumb data={getTree()} target={hrefName}/>, document.getElementById('breadcrumb'));

function getTree() {
  // find the selected node
  return pruneNodes(treeData, 1);
}

function pruneNodes(tree, n = 1) {
  let prunedTree = tree.filter(node => {
    return keepNode(node)
  });
  return (function recurse(nodes, parent = 0, rowLevel = 1) {
    if (nodes) {
      return nodes.map(node => {
        let nodeID = n++;
        return Object.assign({}, node, {
          nodeID: nodeID,
          rowLevel: rowLevel,
          parentNodeID: parent,
          visible: parent === 0,
          expanded: false,
          containsOnlyLeaves: !!node.nodes,
          nodes: recurse(node.nodes, nodeID, rowLevel + 1)
        })
      });
    }
  })(prunedTree);
}

function keepNode(node) {
  if (node.hasOwnProperty('display')) {
    if (node.display.indexOf(env) === -1) {
      return false;
    }
  }
  return true;
}
